<template>
  <div class="search-revenue-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider name="SEARCH_REVENUE_HEADER_COMPONENT_FIELD_NAME_VALIDATE_DATE_RANGE" v-slot="{ errors }" rules>
            <div role="group" class="form-group">
              <label class>{{ $t('SEARCH_REVENUE_HEADER_COMPONENT_LABEL_DATE_RANGE') }}</label>
              <div class="input-group1">
                <vue-monthly-picker v-model="selectedMonth" :clearOption="false"> </vue-monthly-picker>
              </div>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </ValidationProvider>
        </CCol>
      </CRow>
    </ValidationObserver>
    <CRow class="mt-2 form-actions">
      <div class="col-md-3 btn-disabled"></div>
      <CCol md="3" sm="12">
        <CButton class="btn btn-reset" @click="downloadCsv()">{{ $t('SEARCH_REVENUE_HEADER_COMPONENT_DOWNLOAD_CSV_BUTTON') }}</CButton>
      </CCol>
      <CCol md="3" sm="12">
        <CButton class="btn btn-reset" @click="resetForm()">{{ $t('SEARCH_REVENUE_HEADER_COMPONENT_RESET_BUTTON') }}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton class="btn btn-search" @click="search()">{{ $t('SEARCH_REVENUE_HEADER_COMPONENT_SEARCH_BUTTON') }}</CButton>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import dateTimeHelper from '@/utils/datetime-helper';
import VueMonthlyPicker from 'vue-monthly-picker';
import moment from 'moment';
export default {
  name: 'SearchRevenueHeader',
  components: {
    VueMonthlyPicker,
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      selectedMonth: moment(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    onDownloadCsv: {
      type: Function,
      default: null,
    }
  },
  async mounted() {
    this.search();
  },
  methods: {
    resetForm() {
      this.selectedMonth = moment();
      this.search();
    },
    async search() {
      this.dateRange.startDate = dateTimeHelper.lastMonthWithCutOff(this.selectedMonth, 26);
      this.dateRange.endDate = dateTimeHelper.currentMonthWithCutOff(this.selectedMonth, 26);
      const searchData = {
        fromDate: this.dateRange.startDate,
        toDate: this.dateRange.endDate,
      };
      this.onSearch(searchData);
    },
    async downloadCsv() {
      this.dateRange.startDate = dateTimeHelper.lastMonthWithCutOff(this.selectedMonth, 26);
      this.dateRange.endDate = dateTimeHelper.currentMonthWithCutOff(this.selectedMonth, 26);

      const data = {
        fromDate: this.dateRange.startDate,
        toDate: this.dateRange.endDate,
      };
      this.onDownloadCsv(data);
    }
  },
};
</script>
<style lang="scss">
.search-revenue-header-container {
  margin: 20px 0 35px 0;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-reset {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
