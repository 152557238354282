var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"revenue-table"},[_c('h1',{staticClass:"title-revenue-table"},[_vm._v(_vm._s(_vm.$t('LIST_REVENUE_PAGE_LABEL_REVENUE_TABLE')))]),_c('CCard',[_c('CDataTable',{attrs:{"items":_vm.revenues,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","pagination":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))])]}},{key:"platform",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.platform))])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [(item.validator)?_c('td',{staticStyle:{"text-align":"right","padding-right":"50px","white-space":"nowrap"}},[_vm._v(_vm._s(item.validator.name))]):_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}})]}},{key:"address",fn:function(ref){
var item = ref.item;
return [(item.validator)?_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.validator.address))]):_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}})]}},{key:"revenue",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.revenue))])]}},{key:"childpool",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.childpool))])]}},{key:"cayman",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.cayman))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }