<template>
  <div class="revenue-table">
    <h1 class="title-revenue-table">{{ $t('LIST_REVENUE_PAGE_LABEL_REVENUE_TABLE') }}</h1>
    <CCard>
      <CDataTable :items="revenues" :fields="fields" fixed hover striped bordered pagination>
        <template ##="{ item, index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template #platform="{ item }">
          <td style="text-align: right; padding-right: 50px">{{ item.platform }}</td>
        </template>
        <template #name="{ item}">
          <td v-if="item.validator" style="text-align: right; padding-right: 50px; white-space: nowrap;">{{ item.validator.name }}</td>
          <td v-else style="text-align: right; padding-right: 50px"></td>
        </template>
        <template #address="{ item }">
          <td v-if="item.validator" style="text-align: right; padding-right: 50px">{{ item.validator.address }}</td>
          <td v-else style="text-align: right; padding-right: 50px"></td>
        </template>
        <template #revenue="{ item }">
          <td style="text-align: right; padding-right: 50px">{{ item.revenue }}</td>
        </template>
        <template #childpool="{ item }">
          <td style="text-align: right; padding-right: 50px">{{ item.childpool }}</td>
        </template>
        <template #cayman="{ item }">
          <td style="text-align: right; padding-right: 50px">{{ item.cayman }}</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
export default {
  name: 'ListRevenueTable',
  data() {
    return {
      fields: [
        '#',
        {
          key: 'platform',
          label: this.$t('LIST_REVENUE_TABLE_COMPONENT_LABEL_PLATFORM'),
          _style: 'text-align: center;padding-right:50px',
        },
        {
          key: 'name',
          label: this.$t('LIST_REVENUE_TABLE_COMPONENT_LABEL_NAME'),
          _style: 'text-align: center;padding-right:50px',
        },
        {
          key: 'address',
          label: this.$t('LIST_REVENUE_TABLE_COMPONENT_LABEL_ADDRESS'),
          _style: 'text-align: center;padding-right:50px',
        },
        {
          key: 'revenue',
          label: this.$t('LIST_REVENUE_TABLE_COMPONENT_LABEL_REVENUE'),
          _style: 'text-align: right;padding-right:50px',
        },
        {
          key: 'childpool',
          label: this.$t('LIST_REVENUE_TABLE_COMPONENT_LABEL_CHILDPOOL'),
          _style: 'text-align: right;padding-right:50px',
        },
        {
          key: 'cayman',
          label: this.$t('LIST_REVENUE_TABLE_COMPONENT_LABEL_CAYMAN'),
          _style: 'text-align: right;padding-right:50px',
        },
      ],
    };
  },
  props: {
    revenues: {
      type: Array,
      default: () => [],
    },
  }
};
</script>
<style lang="scss">
.revenue-table {
  margin: 35px 0px;
  .title-revenue-table {
    color: #657187;
    // font-family: "SF UI Display";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}
</style>
