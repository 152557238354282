<template>
  <div class="revenue-container">
    <CCard>
      <CCardBody>
        <h1 class="title-revenue">{{ $t('LIST_REVENUE_PAGE_LABEL_REVENUE') }}</h1>
        <SearchRevenueHeader :onSearch="onSearch" :onDownloadCsv="onDownloadCsv" />
        <ListRevenueTable :revenues="revenues" />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import SearchRevenueHeader from '@/components/Revenue/SearchRevenueHeader';
import ListRevenueTable from '@/components/Revenue/ListRevenueTable';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { saveAs } from 'file-saver';
import moment from 'moment';
export default {
  name: 'ListRevenue',
  components: {
    SearchRevenueHeader,
    ListRevenueTable,
  },
  data() {
    return {
      fillterData: {},
      revenues: [],
    };
  },
  methods: {
    async onSearch(searchData) {
      const data = searchData;
      this.getRevenue(data);
    },
    async onDownloadCsv(data) {
      try {
        data = data || {};
        this.filterData = {
          ...this.filterData,
          ...data,
        };
        const filterData = this.filterData;
        const params = {
          start_date: filterData.fromDate,
          end_date: filterData.toDate
        };
        const res = await this.$http.get(endpoints.downloadCsv, { params, responseType: 'blob' });
        const blob = new Blob([res], {type: 'application/zip'});
        const endDateStr = moment(endDateStr).format('YYYY-MM');
        saveAs(blob, `revenue_${endDateStr}.zip`);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_REVENUE_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
    async getRevenue(data) {
      try {
        data = data || {};
        this.filterData = {
          ...this.filterData,
          ...data,
        };
        const filterData = this.filterData;
        const params = {
          start_date: filterData.fromDate,
          end_date: filterData.toDate,
        };
        const result = await this.$http.get(endpoints.getRevenue, { params });
        if (result.data) {
          this.revenues = result.data;
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_REVENUE_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.revenue-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .title-revenue {
    color: #657187;
    // font-family: "SF UI Display";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}
</style>
